* {
  box-sizing: border-box;
}

html,
body {
  font-size: 0.825rem;
  margin: 0;
  line-height: 1;
}

html {
  background-color: var(--background-color-main);
}

body {
  background: var(--background-color-main);
  color: var(--text-primary-color);
  min-height: 100vh;
  min-width: 320px;
  padding: 0;
}

.loader {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.loader::before {
  animation: spin 2s linear infinite;
  border: 16px solid var(--background-color-secondary);
  border-radius: 50%;
  border-top: 16px solid var(--color-active);
  content: "";
  height: 120px;
  width: 120px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.App > .loader {
  min-height: calc(100vh - 6rem);
  padding-bottom: 1.5rem;
  padding-top: 7.5rem;
}

.d-none {
  display: none !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: inherit;
  opacity: 0.4;
  padding-left: 5px;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: inherit;
  opacity: 0.4;
  padding-left: 5px;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: inherit;
  opacity: 0.4;
  padding-left: 5px;
}

input[type="number"] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
  appearance: textfield;
  margin: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  margin: 0;
}

button {
  font: inherit;
}

blockquote,
body,
code,
dd,
div,
dl,
dt,
fieldset,
form,
h1,
h2,
h3,
h4,
h5,
h6,
input,
legend,
li,
ol,
pre,
td,
textarea,
th,
ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

.layout-children {
  width: 100%;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

/****** MOBILE TABLET display none - START ******/
@media screen and (min-width: 1024px) {
  .lg-none {
    display: none !important;
  }
}

@media screen and (min-width: 475px) and (max-width: 1023px) {
  .md-none {
    display: none !important;
  }

  .lg-block {
    display: none !important;
  }
}

@media screen and (max-width: 474px) {
  .xs-none {
    display: none !important;
  }

  .lg-block {
    display: none !important;
  }
}

/****** MOBILE TABLET display none - END ******/

@supports (-webkit-overflow-scrolling: touch) {
  /** prevent zoom in on inputs on mobile devices **/
  select,
  textarea,
  input {
    font-size: 16px !important;
  }
}

/****** MATERIAL UI  ******/
.MuiPopover-root .MuiPaper-root {
  background-color: var(--background-color-main);
  border-radius: 0;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.4);
  padding: 0.2rem;
}
