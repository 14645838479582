.container {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  min-height: 5rem;
  padding: 1rem 2.4rem;
  width: 100%;
}

.link a {
  color: inherit;
}
