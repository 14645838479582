.header {
  align-items: center;
  background-color: var(--background-color-secondary);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  height: 6rem;
  justify-content: space-between;
  padding: 0 3rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
}

.header .headerContent {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.header .logoImg {
  height: 72px;
  width: 120px;
}

.header .btnLink {
  color: var(--text-secondary-color);
  font-size: 16px;
  line-height: 24px;
  padding: 0.5rem;
  text-decoration: underline;
}
